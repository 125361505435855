import React from 'react'

import { Image, Text } from '@chakra-ui/react'

import HorizontalCard from './HorizontalCard'

interface HorizontalGridCardProps {
    media: string
    title: string
    content: string
    titleColor?: string
    w?: string | string[]
    minH?: string | string[]
    maxW?: string | string[]
    mediaW?: string | string[]
    contentW?: string | string[]
    mx?: string
    imagePadding?: string
}

const HorizontalGridCard = ({
    media,
    title,
    content,
    titleColor = 'indigo.500',
    w = ['xs', 'sm', 'xl', 'lg'],
    minH = 'none',
    maxW = 'none',
    mediaW = ['xs', 'sm', 'md'],
    contentW = ['xs', 'sm', 'md'],
    mx = 'auto',
    imagePadding = '0',
}: HorizontalGridCardProps) => (
    <HorizontalCard
        mx={mx}
        w={w}
        minH={minH}
        maxW={maxW}
        mediaW={mediaW}
        contentW={contentW}
        hasCenteredContent
        media={<Image src={media} margin={imagePadding} objectFit="cover" />}
        title={
            <Text as="h3" fontWeight="bold" color={titleColor} mb="4">
                {title}
            </Text>
        }
        content={
            <Text fontSize="sm" color="indigo.500">
                {content}
            </Text>
        }
    ></HorizontalCard>
)

export default HorizontalGridCard
