import React, { ReactElement } from 'react'

import { CardSideStyled, CardStyled } from './FlippingCard.style'

interface FlippingCardProps {
    front: ReactElement
    back: ReactElement
}

const FlippingCard = ({ front, back }: FlippingCardProps) => (
    <CardStyled>
        <CardSideStyled className="card--front">{front}</CardSideStyled>

        <CardSideStyled className="card--back">{back}</CardSideStyled>
    </CardStyled>
)

export default FlippingCard
