import React, { ReactElement } from 'react'

import { Box } from '@chakra-ui/react'

interface GradientCardProps {
    children: ReactElement
    firstColor?: string
    secondColor?: string
    thirdColor?: string
    maxW?: string
    mb?: string
    mx?: string
    py?: string
    px?: string | string[]
}

const GradientCard = ({
    children,
    firstColor = 'var(--chakra-colors-cadet)',
    secondColor = ' var(--chakra-colors-ultramarine)',
    thirdColor = 'var(--chakra-colors-turquoise)',

    ...rest
}: GradientCardProps) => (
    <Box
        shadow="base"
        borderRadius="5xl"
        mx="auto"
        px={[4, 4, 16]}
        py={10}
        bg={`linear-gradient(to top left, ${firstColor} -9%, ${secondColor} 30%, ${thirdColor} 90%)`}
        {...rest}
    >
        {children}
    </Box>
)

export default GradientCard
