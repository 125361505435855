import * as React from 'react'

import image from '@assets/images/not_found.gif'
import icon from '@assets/svg/icon.svg'
import { Box, Flex, HStack, Heading, Image, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import Section from '@components/core/Section'
import { OutlinedButton } from '@components/ui/Button'
import { Card } from '@components/ui/Card'
import { graphql } from 'gatsby'

const NotFoundPage = () => {
    const [isTablet] = useMediaQuery('(max-width: 825px)')

    return (
        <Section pt={10} mb={!isTablet && '130px'}>
            <main>
                <Flex
                    align="center"
                    height={'100%'}
                    gap="2"
                    maxW="750px"
                    mx="auto"
                    direction={isTablet ? 'column-reverse' : 'row'}
                >
                    {isTablet && (
                        <Card size={['xs', 'md']} hasShadow={true} shadow="xl" zIndex={2}>
                            <VStack gap="6">
                                <HStack gap={4} align="start">
                                    <Box>
                                        <Image src={icon} alt="Staffea logo" objectFit="contain" w="100px" />
                                    </Box>

                                    <Text pt="2" color="indigo.500">
                                        Nous sommes désolés, la page que vous recherchez ne semble pas exister...
                                    </Text>
                                </HStack>

                                <OutlinedButton link="/" text="Retour à l'accueil" />
                            </VStack>
                        </Card>
                    )}

                    <Box position="relative">
                        <Heading
                            as="h1"
                            mb="6"
                            fontWeight="bold"
                            fontSize="5xl"
                            color="indigo.500"
                            textAlign={isTablet ? 'center' : 'start'}
                        >
                            Ouuups !<br /> Ça, on l&#39;avait pas planifié !
                        </Heading>

                        {!isTablet && (
                            <Box position="absolute">
                                <Card size="lg" hasShadow={true} shadow="xl" zIndex={2}>
                                    <VStack>
                                        <HStack gap={4} align="start">
                                            <Box>
                                                <Image src={icon} alt="Staffea logo" objectFit="contain" w="100px" />
                                            </Box>

                                            <Text pt="2" color="indigo.500">
                                                Nous sommes désolés, la page que vous recherchez ne semble pas
                                                exister...
                                            </Text>
                                        </HStack>

                                        <Box alignSelf="end">
                                            <OutlinedButton link="/" text="Retour à l'accueil" />
                                        </Box>
                                    </VStack>
                                </Card>
                            </Box>
                        )}
                    </Box>

                    <Box width={isTablet && '70%'}>
                        <Image src={image} borderRadius="50%" />
                    </Box>
                </Flex>
            </main>
        </Section>
    )
}

export default NotFoundPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["404"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
