import React, { ReactElement } from 'react'

import { Box, Flex } from '@chakra-ui/react'

interface HorizontalCardProps {
    media: JSX.Element
    title: JSX.Element
    content: ReactElement
    footer?: JSX.Element
    w?: string | string[]
    minH?: string | string[]
    maxW?: string | string[]
    mediaW?: string | string[]
    contentW?: string | string[]
    mediaFlexBasis?: string | string[]
    isReverse?: boolean
    gap?: string | string[]
    hasCenteredContent?: boolean
    mx?: string
}

const HorizontalCard = ({
    media,
    title,
    content,
    footer,
    minH = 'none',
    mediaW = 'xs',
    contentW = 'sm',
    mediaFlexBasis = '50%',
    isReverse = false,
    gap = '4',
    hasCenteredContent = false,
    mx = 'auto',
    ...rest
}: HorizontalCardProps) => (
    <Box mx={mx} {...rest}>
        <Flex
            gap={gap}
            direction={['column', 'column', isReverse ? 'row-reverse' : 'row']}
            minH={minH}
            alignItems="center"
        >
            <Flex
                w={mediaW}
                flexBasis={mediaFlexBasis}
                justify={hasCenteredContent ? 'center' : 'start'}
                maxWidth="40%"
            >
                {media}
            </Flex>

            <Flex w={contentW} direction="column" justify={hasCenteredContent ? 'center' : 'start'}>
                {title}

                {content}

                {footer}
            </Flex>
        </Flex>
    </Box>
)

export default HorizontalCard
