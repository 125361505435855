import { Box } from '@chakra-ui/react'
import styled from 'styled-components'

export const CardStyled = styled(Box)`
    .card--back,
    .card--front {
        height: 100%;
    }

    perspective: 60rem;
    -moz-perspective: 60rem;

    .card--back {
        transform: rotateY(180deg);
        position: absolute;
        top: 0;
        left: 0;
    }

    :hover .card--front {
        transform: rotateY(-180deg);
    }

    :hover .card--back {
        transform: rotateY(0);
    }
`
export const CardSideStyled = styled.div`
    transition: all 0.8s ease;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
`
